import { defineStore } from 'pinia';
import type { IGame, IGamesRequest, IPaginationMeta } from '@skeleton/core/types';

interface IGameCollectionStoreState {
  cachedGames: { [key: string]: {
      games: IGame[],
      pageMeta: IPaginationMeta
    } };
}

export const useGameCollectionStoreStore = defineStore('gameCollectionStore', {
  state: (): IGameCollectionStoreState => ({
    cachedGames: {},
  }),

  actions: {
    async getGames(defaultRequestParams: IGamesRequest, noCash: boolean = false): Promise<{
      games: IGame[],
      pageMeta: IPaginationMeta
    }> {
      const { getFilteredGames } = useCoreGamesApi();

      const cacheKey = JSON.stringify(defaultRequestParams);

      if (this.cachedGames[cacheKey] && !noCash) {
        return this.cachedGames[cacheKey];
      }

      try {
        const gamesResponse = await getFilteredGames(defaultRequestParams);
        this.cachedGames[cacheKey] = {
          games: gamesResponse?.data,
          pageMeta: gamesResponse?.meta,
        };
      } catch (error) {
        console.error('Error loading games:', error);
        return {
          games: [],
          pageMeta: {
            page: 1,
            perPage: 1,
            totalPages: 1,
            totalRows: 1
          }
        };
      }

      return this.cachedGames[cacheKey];
    },
  },
});
